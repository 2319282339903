<template>
  <div class="th-vendor">
    <h2 class="th-title">Vendor</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 200px"
          v-model:value="search"
          @change="handleSearchVendor"
          placeholder="Masukan Nama / Email Vendor"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Tambahkan Vendor
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Vendor" @ok="handleOk">
          <a-form layout="vertical" :model="formAdd">
            <a-form-item required label="Nama Depan" name="firstName" v-bind="validateInfos.firstName">
              <a-input placeholder="Masukkan nama depan vendor" v-model:value="formAdd.firstName" />
            </a-form-item>
            <a-form-item required label="Nama Belakang" name="lastName" v-bind="validateInfos.lastName">
              <a-input placeholder="Masukkan nama belakang vendor" v-model:value="formAdd.lastName" />
            </a-form-item>
            <a-form-item required label="Email" name="email" v-bind="validateInfos.email">
              <a-input placeholder="Masukkan Email vendor" v-model:value="formAdd.email" />
            </a-form-item>
            <a-form-item label="Gambar Profil" name="profilePhoto">
              <a-upload
                accept="image/jpeg,image/png"
                name="avatar"
                list-type="picture-card"
                :show-upload-list="false"
                :before-upload="file => beforeUpload(file, false)"
              >
                <div v-if="formAdd.profilePhoto">
                  <img width="104" height="104" :src="formAdd.profilePhoto" alt="vendor" />
                </div>
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">Upload Gambar</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit">Tambahkan</a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :columns="columns" :data-source="data" bordered>
          <template #profilePicture="{ record }">
            <a-upload
              accept="image/jpeg,image/png"
              name="avatar"
              :action="`${backendUrl}api/user/upload-avatar`"
              list-type="picture-card"
              :show-upload-list="false"
              :headers="{
                authorization: 'authorization-text',
              }"
              :custom-request="data => customRequestUpload(data, record)"
            >
              <div v-if="record.profilePicture" class="w-100 text-center">
                <img width="104" height="104" :src="`${backendUrl}${record.profilePicture}`" alt="vendor" />
              </div>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload Gambar Profil</div>
              </div>
            </a-upload>
          </template>
          <template #name="{ text, record }">
            <a-form class="w-100" layout="inline" v-if="record.key === idEdit">
              <a-form-item required class="" name="firstName" v-bind="validateInfosEdit.firstName">
                <a-input placeholder="Masukkan nama depan vendor" v-model:value="formEdit.firstName" />
              </a-form-item>
              <a-form-item required class="" name="lastName" v-bind="validateInfosEdit.lastName">
                <a-input placeholder="Masukkan nama belakang vendor" v-model:value="formEdit.lastName" />
              </a-form-item>
            </a-form>
            <span v-else>
              {{text}}
            </span>
          </template>
          <template #email="{ text, record }">
            <a-form layout="vertical" v-if="record.key === idEdit">
              <a-form-item required name="email" v-bind="validateInfosEdit.email">
                <a-input placeholder="Masukkan email vendor" v-model:value="formEdit.email" />
              </a-form-item>
            </a-form>
            <span v-else>
              {{text}}
            </span>
          </template>
          <template #description="{ text, record }">
            <span v-if="record.key === idEdit">
              <a-textarea v-model:value="formEdit.description" />
            </span>
            <span v-else>
              {{text || '-'}}
            </span>
          </template>
          <template #action="{ record }">
            <span v-if="record.key === idEdit">
              <a @click.prevent="confirmEdit(record)">
                <check-outlined></check-outlined>
              </a>
              <a-divider type="vertical" />
              <a @click.prevent="closeEdit">
                <close-outlined></close-outlined>
              </a>
            </span>
            <span v-else>
              <a @click.prevent="vendorAction(record, 'UPDATE')">Ubah</a>
              <a-divider type="vertical" />
              <a @click.prevent="vendorAction(record, 'DELETE')">Hapus Akun</a>
            </span>
        </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import apiClient from '@/services/axios'
const columns = [
  {
    title: 'Gambar Profil',
    dataIndex: 'profilePicture',
    key: 'profilePicture',
    slots: { customRender: 'profilePicture' },
    align: 'center',
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    slots: { customRender: 'email' },
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

function getBase64(img) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', (err) => reject(err));
    reader.readAsDataURL(img);
  })
}

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    LoadingOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let search = ref(null)
    let modalAddVisible = ref(false)
    let formAdd = reactive({
      firstName: null,
      lastName: null,
      email: null,
      profilePhoto: null,
    })
    let formEdit = ref({
      firstName: null,
      lastName: null,
      email: null,
    })
    let idEdit = ref(null)
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      firstName: [
        {
          required: true,
          message: 'Nama Depan harus diisi!',
          trigger: 'change',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Nama Belakang harus diisi!',
          trigger: 'change',
        },
      ],
      email: [
        {
          required: true,
          message: 'Email harus diisi!',
          trigger: 'change',
        },
      ],
    })

    const backendUrl = process.env.VUE_APP_BACKEND_URL

    onMounted(() => {
      fetchVendor()
    })

    const fetchVendor = async (search = '') => {
      const rawData = await store.dispatch('vendor/FETCH_VENDOR', search)
      data.value = rawData.map(vendor => {
        const name = vendor.firstName + ' ' + vendor.lastName
        return {
          key: vendor._id,
          name,
          ...vendor,
        }
      })
    }

    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

    const closeEdit = () => idEdit.value = null

    const confirmEdit = async (record) => {
      try {
        const payload = {
          id: record.key,
          data: {
            firstName: formEdit.value.firstName,
            lastName: formEdit.value.lastName,
            email: formEdit.value.email,
          },
        }
        await validateEdit()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada vendor?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('vendor/UPDATE_VENDOR', payload)
              modalAddVisible.value = false
              fetchVendor()
              notification.success({
                message: `Berhasil Mengubah Data Pada Vendor`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);
    const { validate:validateEdit, validateInfos:validateInfosEdit } = useForm(formEdit, rulesRef);

    const confirmAdd = async () => {
      try {
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan vendor?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const payload = {
                firstName: formAdd.firstName,
                lastName: formAdd.lastName,
                email: formAdd.email,
              }
              const data = await store.dispatch('vendor/ADD_VENDOR', { ...payload })
              console.log(data, 'djfiosjfod')
              if (avatarFile.value) {
                const formData = new FormData()
                formData.append('avatar', avatarFile.value)
                await apiClient.post(`${backendUrl}api/user/upload-avatar`, formData, {
                  params: {
                    idUser: data._id,
                  },
                })
                imageUrl.value = null
                avatarFile.value = null
                modalAddVisible.value = false
              }
              fetchVendor()
              notification.success({
                message: `Berhasil Menambahkan Vendor`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {}
    }

    const vendorAction = async (record, action) => {
      const data = {
        id: record.key,
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus vendor '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('vendor/DELETE_VENDOR', { ...data })
              fetchVendor()
              notification.success({
                message: `Berhasil Menghapus Vendor`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } else if (action === 'UPDATE') {
        idEdit.value  = record.key
        formEdit.value = {
          firstName: record.firstName,
          lastName: record.lastName,
          email: record.email,
        }
        // const data = await store.dispatch(action + '_VENDOR', { id, data })
      }
    }

    // UPLOAD AVATAR

    const loading = ref(false)
    const imageUrl = ref(null)
    const avatarFile = ref(null)
    const beforeUpload = async (file, callRequest = true) => {
      try {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        imageUrl.value = await getBase64(file)
        avatarFile.value = file
        formAdd.profilePhoto = await getBase64(file)

        if (!isJpgOrPng) {
          return message.error('Anda hanya dapat mengunggah file JPG/PNG');
        }


        const loadImage = (url) => new Promise((resolve, reject) => {
          const img = new Image()
          img.addEventListener('load', () => resolve(img));
          img.addEventListener('error', (err) => reject(err));
          img.src = url
        })

        const img = await loadImage(imageUrl.value)
        const dimension = {
          width: img.width,
          height: img.height,
        }


        const isWidthFit = true
        const isRatioFit = true
        // const isRatioFit = Math.round(dimension.width === dimension.height)
        // if (!isRatioFit) {
        //   return message.error('Anda hanya dapat mengunggah file dengan rasio gambar 1:1');
        // }

        // const isWidthFit = dimension.width >= 200 || dimension.height >= 200
        // if (!isWidthFit) {
        //   return message.error('Anda hanya dapat mengunggah file dengan ukuran minimal 200x200');
        // }

        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isLt2M) {
        //   message.error('Image must smaller than 2MB!');
        // }

        // return isJpgOrPng && isLt2M;
        return (isJpgOrPng && isWidthFit && isRatioFit && callRequest) || callRequest;
      } catch(err) {
        console.log(err)
      }
    };

    const customRequestUpload = async (data, record) => {
      try {
        console.log(data)
        const isPassed = await beforeUpload(data.file)
        if (!isPassed)  {
          return ''
        } else {
          if (data.file.status !== 'uploading') {
          }
          const formData = new FormData()
          formData.append(data.filename, data.file)
          const { data: { data:res } } = await apiClient.post(data.action, formData, {
            ...data.headers,
            params: {
              idUser: record._id,
            },
          })

          message.success(`file ${data.file.name} berhasil diunggah.`);
          imageUrl.value = null
          avatarFile.value = null
          formAdd.profilePhoto = null
        }
        fetchVendor()
      } catch (err) {
        message.error(`file ${data.file.name} gagal diunggah.`);
        fetchVendor()
        console.log(err)
      }
    }

    const handleSearchVendor = () => {
      fetchVendor(search.value)
    }

    return {
      data,
      columns,
      search,
      modalAddVisible,
      showModalAdd,
      handleOk,
      formAdd,
      rulesRef,
      loadingAdd,
      confirmAdd,
      validate,
      validateInfos,
      validateEdit,
      validateInfosEdit,
      vendorAction,
      idEdit,
      formEdit,
      closeEdit,
      confirmEdit,
      customRequestUpload,
      backendUrl,
      loading,
      beforeUpload,
      handleSearchVendor,
    }
  },
}
</script>
<style lang="scss">
.th-vendor {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
